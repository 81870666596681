<!-- 系统任务 -->
<template>
  <section class="cont systemTask">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <span class="title">系统任务</span>
      <el-button type="primary" plain class="btn" @click="create">创建任务</el-button>
      <div class="task" v-if="systemList.length">
        <el-card class="item" v-for="(item, index) in systemList" :key="item.id" shadow="hover">
          <div slot="header">
            <span>{{ item.name }}</span>
            <el-switch class="float-r" @change="editState(item, index)" v-model="systemState[index]" active-value="YES" inactive-value="NO"></el-switch>
          </div>
          <div class="text text-ellipis-3">短信内容：{{ item.messageText }}</div>
          <div class="footer">
            <el-link type="primary" @click="edit(item)">编辑</el-link>
          </div>
        </el-card>
      </div>
      <el-empty :image="require('@/assets/group_empty.png')" v-else></el-empty>
    </el-row>

    <!-- 创建/编辑 任务 -->
    <el-dialog :title="pageType === 'create' ? '创建任务' : '编辑任务'" :visible.sync="taskShow" width="600px">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="任务名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称" maxlength="10" show-word-limit class="width-220"></el-input>
        </el-form-item>
        <el-form-item label="选择条件" prop="condition">
          <el-input v-model="form.condition" class="width-220" disabled></el-input>
          <el-button type="primary" size="mini" class="m-left-15" @click="select">选择</el-button>
        </el-form-item>
        <el-form-item label="发送时间" prop="sendType">
          <el-radio-group v-model="form.sendType">
            <el-radio :label="0">立即发送</el-radio>
            <el-radio :label="1">延迟发送</el-radio>
          </el-radio-group>
          <span v-show="form.sendType === 1">
            <el-input-number v-model="form.time" size="mini" class="width-120 m-left-15" :min="1" :max="3600"></el-input-number>
            &nbsp;&nbsp;秒
          </span>
        </el-form-item>
        <el-form-item label="短信内容" prop="content">
          <el-input type="textarea" v-model="form.content" placeholder="【短信签名】 请输入内容" maxlength="60" :rows="4" resize="none" show-word-limit class="width-370"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="handleDel" v-if="pageType === 'edit'">删 除</el-button>
        <el-button @click="taskShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 条件管理 -->
    <el-dialog title="条件管理" :visible.sync="conditionShow" :close-on-click-modal="false" width="800px">
      <condition ref="condition" @condition="condition" @close="conditionShow = false" :all="true" />
    </el-dialog>
  </section>
</template>

<script>
  import { getShortMessage, addShortMessage, editShortMessage, deleteTask } from '@/api/smtech/marketingTask'
  import condition from '@/components/local/conditionMan'
  import { mapState } from 'vuex'
  export default {
    name: 'systemTask',
    components: { condition },
    data() {
      let validCondition = (rule, value, callback) => {
        if (this.form.condition === '未选择') {
          callback(new Error('请选择条件'))
        } else {
          callback()
        }
      }
      let validTimeState = (rule, value, callback) => {
        if (this.form.sendType == 1 && !this.form.time) {
          callback(new Error('请选择发送时间'))
        } else {
          callback()
        }
      }
      return {
        crumbs: new Map([['营销云'], ['营销任务'], ['系统任务']]),
        taskShow: false,       // 任务弹窗状态
        conditionShow: false,  // 条件弹窗状态
        pageType: 'create',    // 任务弹窗类型(创建/编辑)
        count: 0,              // 记录创建任务时打开条件弹窗次数
        form: {
          id: '',              // 编辑时的ID
          name: '',            // 任务名称
          condition: '未选择',  // 选择条件状态文字
          sendType: 0,         // 发送类型
          time: 1,             // 延迟时间(秒)
          propStr: [],         // 用户属性
          touchStr: [],        // 触发条件
          content: '',         // 短信内容
        },
        rules: {
          name: [{ required: true, message: '请输入任务名称', trigger: 'blur' }],
          condition: [{ required: true, validator: validCondition, trigger: 'change' }],
          sendType: [{ required: true, validator: validTimeState, trigger: 'change' }],
          content: [{ required: true, message: '请输入短信内容', trigger: 'blur' }],
        },
        systemList: [],        // 系统任务列表
        systemState: [],       // 系统状态数组

        prompt: '提示！',
        confirm_remove: '是否删除自定义任务？将无法使用，并且在列表删除',
        remove_success: '删除成功！',
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    mounted() {
      this.getTaskList()
    },
    methods: {
      // 任务列表
      getTaskList() {
        let data = { hotelId: this.hotelInfo.id, type: 0 }
        // 系统任务
        getShortMessage(data).then(({ success, records }) => {
          if (success) {
            let systemState = []
            this.systemList = records
            for (let i = 0; i < records.length; i++) {
              systemState.push(records[i].systemState)
            }
            this.systemState = systemState
          }
        })
      },
      // 选择(触发条件弹窗)
      select() {
        this.conditionShow = true
        this.$nextTick(() => {
          if (this.pageType === 'create' && !this.count) {
            this.$refs.condition.transmit(undefined, undefined)
          } else if (this.pageType === 'edit') {
            this.$refs.condition.transmit(this.form.propStr, this.form.touchStr)
          }
          this.count += 1
        })
      },
      // 条件参数
      condition(propStr, touchStr) {
        this.form.propStr = propStr
        this.form.touchStr = touchStr
        this.form.condition = propStr || touchStr ? '已选择' : '未选择'
        this.conditionShow = false
      },
      // 创建
      create() {
        this.taskShow = true
        this.pageType = 'create'
        for (const key in this.form) {
          this.form[key] = ''
        }
        this.form.condition = '未选择'
        this.form.sendType = 0
        this.form.time = 1
        this.count = 0
      },
      // 修改状态
      editState(item, index) {
        let data = {
          id: item.id,
          hotelId: this.hotelInfo.id,
          systemState: this.systemState[index],
        }
        editShortMessage(data).then(({ success }) => {
          if (success) {
            this.$message({ showClose: true, message: '修改成功!', type: 'success' })
            this.getTaskList()
          }
        })
      },
      // 编辑
      edit(item) {
        this.taskShow = true
        this.pageType = 'edit'
        this.form.name = item.name
        this.form.sendType = item.sendType
        this.form.time = item.delayTime || 1
        this.form.propStr = item.propStr
        this.form.touchStr = item.touchStr
        this.form.content = item.messageText
        this.form.id = item.id
        this.form.condition = item.propStr || item.touchStr ? '已选择' : '未选择'
      },
      // 删除
      handleDel() {
        this.$confirm(this.confirm_remove, this.prompt, {
          confirmButtonText: this.confirm,
          cancelButtonText: this.cancel,
          type: 'error',
        }).then(() => {
          deleteTask(this.form.id).then(({ success }) => {
            if (success) {
              this.taskShow = false
              this.getTaskList()
              this.$message.success({ showClose: true, message: this.remove_success, type: 'success' })
            }
          })
        })
      },
      // 确定
      onSubmit(form) {
        this.$refs[form].validate((valid) => {
          if (valid) {
            let data = {
              hotelId: this.hotelInfo.id,
              name: this.form.name,
              propStr: this.form.propStr || '',
              touchStr: this.form.touchStr || '',
              type: 0,
              sendType: this.form.sendType,
              messageText: this.form.content,
            }
            this.form.sendType && (data.delayTime = this.form.time)
            if (this.pageType === 'create') {
              addShortMessage(data).then(({ success }) => {
                if (success) {
                  this.taskShow = false
                  this.$message({ showClose: true, message: '创建成功!', type: 'success' })
                  this.getTaskList()
                }
              })
            } else {
              data.id = this.form.id
              editShortMessage(data).then(({ success }) => {
                if (success) {
                  this.taskShow = false
                  this.$message({ showClose: true, message: '编辑成功!', type: 'success' })
                  this.getTaskList()
                }
              })
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .systemTask {
    .title {
      line-height: 40px;
    }
    .btn {
      float: right;
    }
    .task {
      padding-left: 30px;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 31%;
        margin-top: 40px;
        margin-right: 48px;
        /deep/ .el-card__body {
          padding: 0;
          .text {
            margin: 20px;
            height: 63px;
            text-align: justify;
          }
          .footer {
            padding: 8px 20px;
            text-align: center;
            border-top: 1px solid #ebeef5;
          }
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
</style>
